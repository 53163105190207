/*
 * Copyright (C) 2025 Mahon Media.
 *
 * This source code is protected under international copyright law. All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import React, { useState, useEffect } from "react";
import { ClipLoader } from 'react-spinners';
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import CronJobToggle from "../components/CronJobToggle";
import RateCodeInput from "../components/RateCodeInput";
import QuantityFilter from "../components/QuantityFilter";
import StatsDisplay from "../components/StatsDisplay";
import "./ConversationsDashboard.css";

const ConversationsDashboard = ({onLogout}) => {
  const host = "hub.northviewhg.com";
  const navigate = useHistory();
  const [jobOff, setJobOff] = useState(true);
  const [codes, setCodes] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [config, setConfig] = useState("?");
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({
    emailsProcessed: [
      { date: "2025-01-01", count: 120 },
      { date: "2025-01-02", count: 85 },
    ],
    emailsBySource: [
      { source: "Expedia", count: 200 },
      { source: "Booking.com", count: 150 },
    ],
  });

    useEffect(() => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await fetch(`https://${host}/configurations/active`, {
             method: 'GET',
             headers: {
              'Content-Type': 'application/json',
              'X-API-Key': `${Cookies.get("admin-dashboard")}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            setConfig(data.id);
            setCodes(data.codes);
            setQuantity(data.emails);
            setJobOff(data.emailOff);
            setLoading(false);
          } else {
            console.error("Failed to fetch configuration data");
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching data: ", error);
          setLoading(false);
        }
      };
      fetchData();
    }, []);

    const toggleCron = async (fresh) => {
      try {
        let endpoint;
        if (fresh) {
            endpoint = `https://${host}/configurations/${config}/off`;
        } else {
            endpoint = `https://${host}/configurations/${config}/on`;
        }
        const response = await fetch(endpoint, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': `${Cookies.get("admin-dashboard")}`,
          },
        });
        if (response.ok) {
          console.log("Cron job status updated successfully");
          setJobOff(fresh);
          return fresh;
        } else {
          console.error("Failed to update cron job status");
          return !fresh;
        }
      } catch (error) {
        console.error("Error occurred while toggling cron job:", error);
        return !fresh;
      }
    };

    const updateCodes = async (fresh) => {
      setLoading(true);
      try {
        const response = await fetch(`https://${host}/configurations/${config}`, {
          method: 'PUT',
          body: JSON.stringify(
                {
                    "id": config,
                    "active": true,
                    "emailOff": jobOff,
                    "codes": fresh,
                    emails: 15
                }
          ),
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': `${Cookies.get("admin-dashboard")}`,
          },
        });
        if (response.ok) {
        try {
          const response = await fetch(`https://${host}/configurations/active`, {
             method: 'GET',
             headers: {
              'Content-Type': 'application/json',
              'X-API-Key': `${Cookies.get("admin-dashboard")}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            setConfig(data.id);
            setCodes(data.codes);
            setQuantity(data.emails);
            setJobOff(data.emailOff);
            setLoading(false);
          } else {
            console.error("Failed to fetch configuration data");
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching data: ", error);
          setLoading(false);
        }
        } else {
          console.error("Failed to update config");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error occurred while updating the config:", error);
        setLoading(false);
      }
    };

    const updateQuantity = async (fresh) => {
          if (fresh == "All") {
            fresh = 2147483647;
          }
          setLoading(true);
          try {
            const response = await fetch(`https://${host}/configurations/${config}`, {
              method: 'PUT',
              body: JSON.stringify(
                    {
                        "id": config,
                        "active": true,
                        "emailOff": jobOff,
                        "codes": codes,
                        emails: fresh
                    }
              ),
              headers: {
                'Content-Type': 'application/json',
                'X-API-Key': `${Cookies.get("admin-dashboard")}`,
              },
            });
            if (response.ok) {
            try {
              const response = await fetch(`https://${host}/configurations/active`, {
                 method: 'GET',
                 headers: {
                  'Content-Type': 'application/json',
                  'X-API-Key': `${Cookies.get("admin-dashboard")}`,
                },
              });
              const data = await response.json();
              if (response.ok) {
                setConfig(data.id);
                setCodes(data.codes);
                setQuantity(data.emails);
                setJobOff(data.emailOff);
                setLoading(false);
              } else {
                console.error("Failed to fetch configuration data");
                setLoading(false);
              }
            } catch (error) {
              console.error("Error fetching data: ", error);
              setLoading(false);
            }
            } else {
              console.error("Failed to update config");
              setLoading(false);
            }
          } catch (error) {
            console.error("Error occurred while updating the config:", error);
            setLoading(false);
          }
        };

  return (
  <div className="dashboard">
        <h1 className="dashboard__title">Beacon Third Party Email Conversation #{config}</h1>
        {loading ? (
          <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <ClipLoader color="#3498db" loading={loading} size={50} />
          </div>
        ) : (
          <div className="dashboard__container">
            <CronJobToggle
              off={jobOff}
              onToggle={() => toggleCron(!jobOff)}
            />
            <RateCodeInput value={codes} onChange={updateCodes}/>
            <QuantityFilter value={quantity} onChange={setQuantity} update={updateQuantity}/>
            <StatsDisplay/>
          </div>
        )}
        <button className="logout-button" onClick={onLogout}>
          Logout
        </button>
      </div>
  );
};

export default ConversationsDashboard;
