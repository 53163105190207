/*
 * Copyright (C) 2025 Mahon Media.
 *
 * This source code is protected under international copyright law. All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spin, Form, Input, Button, Card, Typography, message } from "antd";
import "./FreeDrinkCoupon.css";

const { Title, Text } = Typography;

const FreeDrinkCoupon = () => {
  const { sha } = useParams();
  const host = "hub.northviewhg.com";
  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState();
  const [email, setEmail] = useState();
  const [key, setKey] = useState();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(`https://${host}/coupons/${sha}`, {
           method: 'GET',
           headers: {
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        if (response.ok) {
          setReservation(data.reservation);
          setKey(data.key);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error("Failed to fetch coupon");
        }
      } catch (error) {
        setLoading(false);
        window.location.href = "https://www.beacongrand.com/";
      }
    };
    fetchData();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
    const response = await fetch(
      `https://${host}/conversations/${reservation}`,
      {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': key,
        },
        body: JSON.stringify({ email: values.email })
      }
    );
    if (response.status == 200) {
      setLoading(false);
      message.success("Coupon redeemed successfully! You can close this page.");
    } else {
      setLoading(false);
      throw new Error("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong while redeeming your coupon. Please try again.");
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Failed to redeem coupon. Please check your input.");
  };

  return (
    <div className="free-drink-coupon-container">
      <Card className="free-drink-coupon-card" bordered={false}>
        <Title level={3} className="free-drink-coupon-title">
          Enter your email to receive free drinks
        </Title>
        <Text className="free-drink-coupon-subtitle">
          Use your email to redeem a free drink.
          <br />
        </Text>
        <Spin spinning={loading} tip="Processing...">
        <Form
          name="redeemCoupon"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: 'email', message: "Please enter a valid email address" },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Redeem Coupon
            </Button>
          </Form.Item>
        </Form>
        </Spin>
      </Card>
    </div>
  );
};

export default FreeDrinkCoupon;
