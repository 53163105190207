import Cookies from "js-cookie";
import React, {useEffect, useState} from "react";
import {useNotification} from "../contexts/NotificationContext";
import {
  Box,
  Button,
  Card,
  CardContent, Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {Modal} from "react-overlays";
import {PROFILE_OPTIONS} from "../constants/selectOptions";
import ProfileSelect from "../components/ProfileSelect";

function RoomTypes() {
  const {setIsOpen, setIsFailed, setMessage} = useNotification();
  const [env, setEnv] = useState('beacon');
  const [roomTypes, setRoomTypes] = useState([]);
  const [room, setRoom] = useState();
  const [used, setUsed] = useState();
  const [image, setImage] = useState();
  const [description, setDescription] = useState();
  const [required, setRequired] = useState();
  const [allowed, setAllowed] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const token = Cookies.get("jwtToken");
    setLoading(true);
    setShowModal(false);
    fetch(
      `https://hub.northviewhg.com/api/v1/room-types/with-url?env=${env}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        }
      }
    ).then(response => {
      if (response.status === 200) {
        setIsFailed(false);
        setLoading(false);
        return response.json();
      } else {
        if (response.status === 500) {
          throw new Error(
            "Something went wrong"
          );
        }
      }
    }).then(data => {
      setRoomTypes(data);
    }).catch((err) => {
      setIsOpen(true);
      setIsFailed(true);
      setMessage(err.message);
      setLoading(false);
    })
  }, [env]);

  const toggleEnv = (e) => {
    const notificationName =
      e.target.value === "sandbox" ? "Opera Sandbox" : "Beacon Grand";
    setIsOpen(true);
    setEnv(prev => prev === 'sandbox' ? 'beacon' : 'sandbox');
    setIsFailed(false);
    setMessage(`Profile is set to ${notificationName}`);
  };

  const showUpdate = (rm, img, dsc, used, req, allow) => {
    setRoom(rm);
    setImage(img);
    setDescription(dsc);
    setShowModal(true);
    setUsed(used);
    setRequired(req);
    setAllowed(allow);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const updateRoomType = (e) => {
    e.preventDefault();
    const token = Cookies.get("jwtToken");
    setLoading(true);
    fetch('https://hub.northviewhg.com/api/v1/room-types', {
      method: 'PUT',
      body: JSON.stringify({
        code: room,
        image: image,
        description: description,
        requiredAvailable: required,
        allowOversoldUpTo: allowed
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        "Authorization": `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.ok) {
        setIsOpen(true);
        setIsFailed(false);
        setMessage(
          `Room Type ${room} has been updated`
        );
        const again = `https://hub.northviewhg.com/api/v1/room-types/with-url?env=${env}`;
        fetch(again, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((rs) => {
          rs.json().then(
            (fresh) => {
              setRoomTypes(fresh);
              setLoading(false);
              setShowModal(false);
            }
          );
        });
      } else {
        if (response.status === 400) {
          throw new Error(
            response.statusMessage
          );
        }
      }
    }).catch((err) => {
      setIsOpen(true);
      setIsFailed(true);
      setMessage(err.message);
      setLoading(false);
    });
  }

  const renderBackdrop = (props) => {
    <div className="backdrop" {...props} />;
  };

  const saveCheckbox = (event, room) => {
    const token = Cookies.get("jwtToken");
    setLoading(true);
    fetch('https://hub.northviewhg.com/api/v1/room-types/upsell', {
      method: 'PATCH',
      body: JSON.stringify({
        code: room,
        checked: event.target.checked
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        "Authorization": `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.ok) {
        setIsOpen(true);
        setIsFailed(false);
        setMessage(
          `Room Type ${room} in use for Upsell: ${event.target.checked}`
        );
        const again = `https://hub.northviewhg.com/api/v1/room-types/with-url?env=${env}`;
        fetch(again, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((rs) => {
          rs.json().then(
            (fresh) => {
              setRoomTypes(fresh);
              setLoading(false);
              setShowModal(false);
            }
          );
        });
      } else {
        if (response.status === 400) {
          throw new Error(
            response.statusMessage
          );
        }
      }
    }).catch((err) => {
      setIsOpen(true);
      setIsFailed(true);
      setMessage(err.message);
      setLoading(false);
    });
    setUsed(event.target.checked);
  };

  return (
    <ul>
      {loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}>
          {loading && <CircularProgress sx={{
            mt: 3,
            display: 'grid',
            placeItems: 'center',
            height: '100vh'
          }}/>}
        </div>
      ) : (
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
          <Box position="absolute" top="-7px">
            <ProfileSelect
              handleSelectValue={toggleEnv}
              selectedValue={env}
              options={PROFILE_OPTIONS}
            />
          </Box>
          {roomTypes.map(roomType => (
            <Card key={roomType.code} style={
              {
                margin: '10px', width: '200px', height: '300px',
                backgroundImage: roomType.image
              }
            }
            >
              <div className="scrollable-content">
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {roomType.code}
                    <Checkbox checked={roomType.usedForUpsell}
                              onClick={() => saveCheckbox(event, roomType.code)}></Checkbox>
                  </Typography>
                  <p>{roomType.friendly}</p>
                  <img
                    src={roomType.image}
                    style={{width: '75px', height: '75px'}}
                    alt="image"
                  />
                  <p>{roomType.description}</p>
                  <Button
                    onClick={() => showUpdate(
                      roomType.code, roomType.image, roomType.description,
                      roomType.usedForUpsell,
                      roomType.requiredAvailable,
                      roomType.allowOversoldUpTo
                    )}>
                    Edit
                  </Button>
                </CardContent>
              </div>
            </Card>
          ))}
        </div>
      )}
      <Modal
        className="modal"
        show={showModal}
        onHide={handleCancel}
        renderBackdrop={renderBackdrop}
      >
        <div>
          <div className="modal-header">
            <div className="modal-title">Update Room Type Details</div>
            <div>
              <span className="close-button" onClick={handleCancel}>
                x
              </span>
            </div>
          </div>
          <form onSubmit={updateRoomType}>
            <FormControl sx={{
              display: 'flex'
            }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} mdOffset={3}
                      sx={{marginLeft: '20px'}}>
                  <TextField
                    sx={{
                      mt: 2,
                      width: '100%',
                      height: '50px',
                      "& input": {
                        padding: "0.375rem 0.75rem",
                        fontSize: '1.5rem',
                      },
                    }}
                    label="Room Code"
                    value={room}
                    onChange={(e) => setRoom(e.target.value)}
                    disabled
                  />
                  <TextField
                    sx={{
                      mt: 2,
                      width: '100%',
                      height: '50px',
                      "& input": {
                        padding: "0.375rem 0.75rem",
                        fontSize: '1.5rem',
                      },
                    }}
                    label="Image URL"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  />
                  <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    label="Description"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    // onKeyDown={handleKeyDown}
                    fullWidth
                  />
                  <TextField
                    sx={{
                      mt: 2,
                      width: '100%',
                      height: '50px',
                      "& input": {
                        padding: "0.375rem 0.75rem",
                        fontSize: '1.5rem',
                      },
                    }}
                    label="Required Available for Upsell"
                    value={required}
                    onChange={(e) => setRequired(e.target.value)}
                    type={"number"}
                  />
                  <TextField
                    sx={{
                      mt: 2,
                      width: '100%',
                      height: '50px',
                      "& input": {
                        padding: "0.375rem 0.75rem",
                        fontSize: '1.5rem',
                      },
                    }}
                    label="Allow Oversold Up To"
                    value={allowed}
                    onChange={(e) => setAllowed(e.target.value)}
                    type={"number"}
                  />
                  {used ? "This room type used in Upsell" : ""}
                </Grid>
              </Grid>
            </FormControl>
            <FormControl sx={
              {
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }
            }>
              {loading && <CircularProgress sx={{mt: 3}}/>}
            </FormControl>
            <div className="modal-desc">
            </div>
            <div className="modal-footer">
              <button className="secondary-button" onClick={handleCancel}>
                Cancel
              </button>
              <Button
                type="submit"
                variant={"contained"}
                sx={{
                  textTransform: "none",
                }}
                color="primary"
              >
                Update
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </ul>
  );
}

export default RoomTypes;
