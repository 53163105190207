/*
 * Copyright (C) 2025 Mahon Media.
 *
 * This source code is protected under international copyright law. All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const StatsDisplay = () => {
 const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
 const [processed, setProcessed] = useState("?");

  useEffect(() => {
     stats(selectedDate);
   }, []);

  const stats = async(date) => {
    setSelectedDate(date);
    const response = await fetch(`https://hub.northviewhg.com/stats?date=${date}`, {
        headers: {
        'Content-Type': 'application/json',
        'X-API-Key': `${Cookies.get("admin-dashboard")}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      if (data.length == 0) {
        setProcessed(0);
      } else {
        setProcessed(data[0].emails);
      }
    }
  }

  return (
    <div>
      <h3>Emails Stats</h3>
      <div>
        <input type="date" value={selectedDate} onChange={(event) => stats(event.target.value)}/>
        {processed == '?' ? '?' : `${processed} processed`}
      </div>
    </div>
  );
};

export default StatsDisplay;
