/*
 * Copyright (C) 2025 Mahon Media.
 *
 * This source code is protected under international copyright law. All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import React, { useState, useEffect } from "react";
import "./RateCodeInput.css";

const RateCodeInput = ({ value, onChange }) => {

  const [isEditing, setIsEditing] = useState(false);
  const [codes, setCodes] = useState(value);
  const [newCode, setNewCode] = useState('');

  const handleAddCode = () => {
    if (newCode && !codes.includes(newCode)) {
      setCodes([...codes, newCode]);
      setNewCode('');
    }
  };

  const handleRemoveCode = (code) => {
    setCodes(codes.filter((item) => item !== code));
  };

  const handleToggleEditMode = () => {
      setIsEditing(!isEditing);
  };

  return (
      <div className="rate-code-manager">
        <h3>Rate Codes</h3>
        {!isEditing ? (
            <div>
              {codes.length === 0 ? (
                <div>No rate codes available</div>
              ) : (
                <div style={{ fontSize: '12px', color: '#555' }}>
                  {codes.map((code, index) => (
                    <span key={index}>
                      {code}{index < codes.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </div>
              )}
            </div>
          ) : (
        <div>
          <ul>
            {codes.length === 0 ? (
              <li>No rate codes available</li>
            ) : (
              codes.map((code, index) => (
                <li key={index}>
                  {code}
                  <button
                    onClick={() => handleRemoveCode(code)}
                    style={{"backgroundColor": "#f44336"}}
                    >-
                  </button>
                </li>
              ))
            )}
          </ul>
          <div>
            <input
              type="text"
              value={newCode}
              onChange={(e) => setNewCode(e.target.value)}
              placeholder="Enter new rate code"
            />
            <button onClick={handleAddCode}>+</button>
          </div>
          <button onClick={()=> onChange(codes)}>Update Rate Codes</button>
        </div>
      )}
      <button onClick={handleToggleEditMode}>
        {isEditing ? 'Cancel Editing' : 'Edit Rate Codes'}
      </button>
    </div>
  );
};

export default RateCodeInput;
