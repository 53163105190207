/*
 * Copyright (C) 2025 Mahon Media.
 *
 * This source code is protected under international copyright law. All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import React from "react";

const QuantityFilter = ({ value, onChange, update }) => {
  const options = [25, 50, 75, 100, 150, 200, 250, "All"];

  return (
    <div>
      <h3>Quantity Filter</h3>
      <select value={value == 2147483647 ? "All" : value } onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <p>Current Quantity: {value == 2147483647 ? "All" : value}</p>
      <button onClick={() => update(value)}>Update Quantity</button>
    </div>
  );
};

export default QuantityFilter;
