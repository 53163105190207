/*
 * Copyright (C) 2025 Mahon Media.
 *
 * This source code is protected under international copyright law. All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
import React from "react";
import PropTypes from "prop-types";
import "./CronJobToggle.css";

const CronJobToggle = ({ off, onToggle }) => {
  return (
    <div className="cron-job-toggle">
      <h3>Email Cron Job</h3>
      <button
        className={`cron-job-toggle__button ${
          off ? "inactive" : "active"
        }`}
        onClick={onToggle}
      >
        {off ? "OFF" : "ON"}
      </button>
    </div>
  );
};

CronJobToggle.propTypes = {
  status: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default CronJobToggle;
